// action types
export const ADD_PRODUCT = "addProduct";
export const DEL_PRODUCT = "delProduct";
export const RESET = "reset";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  cart: {
    lines: [],
    total: 0
  }
};

const getters = {
  cart(state) {
    return state.cart;
  }
};

const actions = {
  [ADD_PRODUCT](context, product) {
    context.commit(ADD_PRODUCT, product);
  },
  [DEL_PRODUCT](context, product) {
    context.commit(DEL_PRODUCT, product);
  },
  [RESET](context) {
    context.commit(RESET);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [RESET](state) {
    state.cart = {
      lines: [],
      total: 0
    };
  },
  [ADD_PRODUCT](state, product) {
    let cart = {
      lines: [],
      total: 0
    };

    let found = false;
    for (let line of state.cart.lines) {
      if (line.product.id === product.id) {
        line.quantity += 1;
        found = true;
      }
      cart.total += line.quantity * line.product.euros;
      cart.lines.push(line);
    }
    if (!found) {
      cart.total += product.euros;
      cart.lines.push({
        product: product,
        quantity: 1
      });
    }
    state.cart = cart;
    state.errors = null;
  },
  [DEL_PRODUCT](state, product) {
    let cart = {
      lines: [],
      total: 0
    };

    for (let line of state.cart.lines) {
      if (line.product.id === product.id) {
        line.quantity -= 1;
        if (line.quantity === 0) {
          continue;
        }
      }
      cart.total += line.quantity * line.product.euros;
      cart.lines.push(line);
    }

    state.cart = cart;
    state.errors = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
