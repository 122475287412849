import ApiService from "@/core/services/api.service";

// action types
export const LOAD_DATA = "loadData";

// mutation types
export const SET_BUSINESS = "setBusiness";
export const SET_BRANDS = "setBrands";
export const SET_PRODUCTS = "setProducts";
export const SET_PLATFORMS = "setPlatforms";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  brands: null,
  products: null,
  platforms: null,
  business: null
};

const getters = {
  business(state) {
    return state.business;
  },
  brands(state) {
    return state.brands;
  },
  platforms(state) {
    return state.platforms;
  },
  products(state) {
    return state.products;
  }
};

const actions = {
  [LOAD_DATA](context, businessCode) {
    if (!businessCode) {
      window.location.href = process.env.VUE_APP_API_URL;
      return;
    }
    return new Promise(resolve => {
      ApiService.post("misc", { code: businessCode.toUpperCase() })
        .then(({ data }) => {
          if (!data.business || data.platforms.length === 0) {
            window.location.href = process.env.VUE_APP_API_URL;
            return;
          }
          data.business.code = businessCode.toUpperCase();
          context.commit(SET_BUSINESS, data.business);
          context.commit(SET_BRANDS, data);
          context.commit(SET_PRODUCTS, data.products);
          context.commit(SET_PLATFORMS, data.platforms);
          resolve(data);
        })
        .catch(({ response }) => {
          window.location.href = process.env.VUE_APP_API_URL;
          context.commit(SET_ERROR, response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_BRANDS](state, data) {
    state.brands = data.brands.filter(b => {
      for (let product of data.products) {
        if (b.id === product.brandId) {
          return true;
        }
      }
      return false;
    });
  },
  [SET_PLATFORMS](state, data) {
    state.platforms = data;
  },
  [SET_PRODUCTS](state, data) {
    state.products = data;
  },
  [SET_BUSINESS](state, data) {
    state.business = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
